import { FC, ReactNode } from 'react';

import { Container } from '@chakra-ui/react';

export const PageContainer: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Container maxW='container.xl' mx='auto' px='4'>
      <>{children}</>
    </Container>
  );
};

export const ContentContainer: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Container maxW='container.lg' mx='auto' px='4'>
      <>{children}</>
    </Container>
  );
};

export const DashboardContainer: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Container maxW='6xl' mx='auto' px='4'>
      <>{children}</>
    </Container>
  );
};
