import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Tooltip } from '@chakra-ui/react';

import { RootState } from '~/store';

import { updateRecipientCount } from './slice';

export const RecipientSlider: FC<{ defaultValue?: number }> = ({ defaultValue }) => {
  const dispatch = useDispatch();

  const { recipientCount } = useSelector((state: RootState) => state.recipientCount);

  useEffect(() => {
    if (!defaultValue) return;

    dispatch(updateRecipientCount(defaultValue));
  }, [defaultValue, dispatch]);

  return (
    <Box pt={12}>
      <Slider
        aria-label='Select number of recipients'
        defaultValue={defaultValue ? defaultValue : recipientCount}
        min={1}
        max={20}
        onChange={(value: number) => {
          dispatch(updateRecipientCount(value));
        }}
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <Tooltip
          label={`${recipientCount} Recipient${recipientCount === 1 ? '' : 's'}`}
          placement='top'
          bgColor='gray'
          fontSize='lg'
          borderRadius='md'
          px='2'
          py='1'
          isOpen
          hasArrow
        >
          <SliderThumb />
        </Tooltip>
      </Slider>
    </Box>
  );
};
