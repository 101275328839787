import { FC, ReactNode } from 'react';

import { Box } from '@chakra-ui/react';

export const PageLayout: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <>
      <Box w='full' minHeight='100vh' bgGradient='linear(to-br, #E9FEF4, #FEFCEA, #F8F0F0)'>
        <>{children}</>
      </Box>
    </>
  );
};
