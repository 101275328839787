import type { NextPage } from 'next';

import { Box } from '@chakra-ui/react';

import { PageLayout } from '~/components/page-layout';
import { FeaturesSection } from '~/features/home-page/features-section';
import { Footer } from '~/features/home-page/footer';
import { Header } from '~/features/home-page/header';
import { Hero } from '~/features/home-page/hero';
import { HowItWorksSection } from '~/features/home-page/how-it-works-section';
import { PriceSection } from '~/features/home-page/price-section';
import { useResetPasswordRedirect } from '~/hooks/use-reset-password-redirect';

const Home: NextPage = () => {
  useResetPasswordRedirect();

  return (
    <PageLayout>
      <Box h={{ base: 8, lg: 16 }} />
      <Header />
      <main>
        <Hero />
        <HowItWorksSection />
        <FeaturesSection />
        <PriceSection />
      </main>
      <Footer />
    </PageLayout>
  );
};

export default Home;
