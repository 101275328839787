import { FC } from 'react';
import Image from 'next/image';

import { Box, Flex, Heading, Stack, Text } from '@chakra-ui/react';

import { ContentContainer } from '~/components/containers';
import { verticalSectionSpacing } from '~/styles';

type Step = {
  id: number;
  imageSrc: string;
  title: string;
  description: string;
};

const steps: Step[] = [
  {
    id: 1,
    imageSrc: '/images/camera.png',
    title: 'Upload photos',
    description: 'Capture moments in your life and upload them to Joyline.',
  },
  {
    id: 2,
    imageSrc: '/images/person.png',
    title: 'Add recipients',
    description: 'Tell Joyline who to send photos to every month.',
  },
  {
    id: 3,
    imageSrc: '/images/mail.png',
    title: 'Photos are delivered',
    description: 'Once a month photos will be delivered to your recipients.',
  },
];

export const HowItWorksSection: FC = () => {
  return (
    <Box as='section' bgColor='white' py={verticalSectionSpacing} zIndex={1} pos='relative'>
      <ContentContainer>
        <Heading as='h2' size='home.h2' mb={16} textAlign='center'>
          How Joyline works
        </Heading>
        <Flex
          gap={{ base: 16, md: 8 }}
          justifyContent='space-between'
          alignItems={{ base: 'center', md: 'flex-start' }}
          flexDir={{ base: 'column', md: 'row' }}
        >
          {steps.map((step) => (
            <StepItem key={step.id} step={step} />
          ))}
        </Flex>
      </ContentContainer>
    </Box>
  );
};

const StepItem: FC<{ step: Step }> = ({ step }) => {
  return (
    <Flex flexDir={{ base: 'row', md: 'column' }} gap={4} flex={1} textAlign='center' maxW='xs' alignItems='center'>
      <Box flex={1}>
        <Image src={step.imageSrc} width={207} height={189} alt='' />
      </Box>
      <Stack spacing={2} flex={2}>
        <Heading as='h3' size='h3' textAlign={{ base: 'left', md: 'center' }}>
          {step.title}
        </Heading>
        <Text size='home.body' textAlign={{ base: 'left', md: 'center' }}>
          {step.description}
        </Text>
      </Stack>
    </Flex>
  );
};
