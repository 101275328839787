import { FC, useMemo } from 'react';
import Image from 'next/image';
import { IoHeartCircle } from 'react-icons/io5';

import { Box, Container, Flex, Heading, Icon, List, ListItem, Stack, Text } from '@chakra-ui/react';

import { ContentContainer } from '~/components/containers';
import { useGetPricesQuery } from '~/services/joyline';
import { verticalSectionSpacing } from '~/styles';

export const FeaturesSection: FC = () => {
  const { data: prices } = useGetPricesQuery();

  const monthlyPrice = useMemo(() => {
    return prices?.find((price) => price.billingPeriod === 'monthly')?.amount;
  }, [prices]);

  return (
    <Box bgGradient='linear(to-br, #E9FEF4, #FEFCEA, #F8F0F0)' py={verticalSectionSpacing}>
      <ContentContainer>
        <Flex
          as='section'
          justifyContent='space-between'
          alignItems='center'
          flexDir={{ base: 'column', lg: 'row' }}
          gap={8}
        >
          <Box>
            <Image
              src='/images/happy-seniors2.png'
              width={608}
              height={490}
              alt='Happy senior couple looking at photos'
            />
          </Box>
          <Container maxW='lg'>
            <Stack spacing={4}>
              <Heading as='h2' size='home.h1'>
                Make a loved one&apos;s day for only ${monthlyPrice}
              </Heading>
              <Text size='home.body'>
                Subscribe today and share some joy to your loved ones every month. Joyline makes it easy to print and
                send your favorite pictures to people who prefer print over a screen.
              </Text>
              <List spacing={3}>
                <ListItem display='flex' alignItems={{ base: 'flex-start', lg: 'center' }} gap={1}>
                  <Icon as={IoHeartCircle} color='orange' w={6} h={6} mt='1px' />
                  <Heading as='h4' size='h4' color='medium-gray'>
                    3 high quality 4x6 photos printed and delivered
                  </Heading>
                </ListItem>
                <ListItem display='flex' alignItems={{ base: 'flex-start', lg: 'center' }} gap={1}>
                  <Icon as={IoHeartCircle} color='orange' w={6} h={6} mt='1px' />
                  <Heading as='h4' size='h4' color='medium-gray'>
                    You choose who receives photos every month
                  </Heading>
                </ListItem>
                <ListItem display='flex' alignItems={{ base: 'flex-start', lg: 'center' }} gap={1}>
                  <Icon as={IoHeartCircle} color='orange' w={6} h={6} mt='1px' />
                  <Heading as='h4' size='h4' color='medium-gray'>
                    Delivered anywhere within the US
                  </Heading>
                </ListItem>
              </List>
            </Stack>
          </Container>
        </Flex>
      </ContentContainer>
    </Box>
  );
};
