import { FC, useMemo } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useSelector } from 'react-redux';

import { Box, Button, Container, Flex, Heading, Stack, Text } from '@chakra-ui/react';

import { ContentContainer } from '~/components/containers';
import { RecipientSlider } from '~/features/recipient-count';
import { useGetPricesQuery } from '~/services/joyline';
import { RootState } from '~/store';
import { verticalSectionSpacing } from '~/styles';

export const PriceSection: FC = () => {
  const { recipientCount } = useSelector((state: RootState) => state.recipientCount);

  const { data: prices } = useGetPricesQuery();

  const monthlyPrice = useMemo(() => {
    return prices?.find((price) => price.billingPeriod === 'monthly')?.amount;
  }, [prices]);

  const yearlyPrice = useMemo(() => {
    return prices?.find((price) => price.billingPeriod === 'yearly')?.amount;
  }, [prices]);

  return (
    <Box bgColor='white' py={verticalSectionSpacing} zIndex={1} pos='relative'>
      <ContentContainer>
        <Stack as='section' spacing={16} alignItems='center'>
          <Heading as='h2' size='home.h2' textAlign='center'>
            Tiny price; lots of joy
          </Heading>

          <Flex alignItems='center' justifyContent='center' gap={8}>
            <Box pos='relative'>
              <Box>
                <Image src='/images/blob4.png' width={198} height={109} alt='' />
              </Box>
              <Flex
                pos='absolute'
                w='full'
                h='full'
                top={0}
                left={0}
                flexDir='column'
                alignItems='center'
                justifyContent='center'
              >
                {monthlyPrice && (
                  <Text fontSize='5xl' fontWeight='bold' lineHeight='none'>
                    ${recipientCount * monthlyPrice}/m
                  </Text>
                )}
                <Text>billed monthly</Text>
              </Flex>
            </Box>
            <Box pos='relative'>
              <Box>
                <Image src='/images/blob5.png' width={198} height={109} alt='' />
              </Box>
              <Flex
                pos='absolute'
                w='full'
                h='full'
                top={0}
                left={0}
                flexDir='column'
                alignItems='center'
                justifyContent='center'
                gap={1}
              >
                {yearlyPrice && (
                  <Text fontSize='5xl' fontWeight='bold' lineHeight='none'>
                    ${recipientCount * yearlyPrice}/m
                  </Text>
                )}
                <Text>billed yearly</Text>
              </Flex>
            </Box>
          </Flex>
          <Container maxW='3xl' mx='auto'>
            <RecipientSlider />
          </Container>
          <Link href='/sign-up'>
            <a>
              <Button size='lg'>Get started</Button>
            </a>
          </Link>
        </Stack>
      </ContentContainer>
    </Box>
  );
};
