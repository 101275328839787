import { FC } from 'react';
import Link from 'next/link';

import { Box, Flex, Link as ChakraLink } from '@chakra-ui/react';

export const FooterNav: FC<{ colorScheme: 'medium-gray' | 'green' }> = ({ colorScheme }) => {
  return (
    <Flex gap={3} justifyContent='center' alignItems='center'>
      <Link href='/' passHref>
        <ChakraLink variant='nav' color={colorScheme}>
          &copy; Joyline
        </ChakraLink>
      </Link>
      <Box w='2px' height='16px' bgColor={colorScheme} opacity='.2' />
      <Link href='/support' passHref>
        <ChakraLink variant='nav' color={colorScheme}>
          Support
        </ChakraLink>
      </Link>
      <Box w='2px' height='16px' bgColor={colorScheme} opacity='.2' />
      <Link href='/privacy' passHref>
        <ChakraLink variant='nav' color={colorScheme}>
          Privacy
        </ChakraLink>
      </Link>
      <Box w='2px' height='16px' bgColor={colorScheme} opacity='.2' />
      <Link href='/terms' passHref>
        <ChakraLink variant='nav' color={colorScheme}>
          Terms
        </ChakraLink>
      </Link>
    </Flex>
  );
};
