import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { supabase } from '~/libs/supabase';
import { getParamsFromUrlHash } from '~/utils';

export const useResetPasswordRedirect = () => {
  const router = useRouter();

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange((event) => {
      if (event === 'PASSWORD_RECOVERY') {
        if (typeof window === 'undefined') return;
        const params = getParamsFromUrlHash(window.location.hash);
        router.push(`/reset-password?accessToken=${params?.access_token}`);
      }
    });

    return () => {
      authListener?.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
