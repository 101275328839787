/**
 * When resetting a password with supabase, they redirect to this url: domain.com/#access_token=ABC&expires_in=3600&refresh_token=XYZ&token_type=bearer&type=recovery
 * We have to parse the hash value, which contains query parameters and respond accordingly
 */

export type HashParams = { [x: string]: string };

export const getParamsFromUrlHash = (hashValue: string): HashParams | undefined => {
  // Returns: "access_token=ABC&expires_in=3600&refresh_token=XYZ&token_type=bearer&type=recovery"
  const urlHashValue = hashValue.replace('#', '');
  const hashQueryParams = urlHashValue.split('&');

  if (!hashQueryParams.length) return;

  /** Result:
    params = {
      access_token: "ABC",
      expires_in: "3600",
      refresh_token: "XYZ",
      token_type: "bearer",
      type: "recovery",
    }
  */
  const params = hashQueryParams
    .map((hashQueryParam) => {
      const [key, value] = hashQueryParam.split('=');
      return { [key]: value };
    })
    .reduce((r, c) => Object.assign(r, c), {});

  return params;
};
