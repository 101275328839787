import { FC } from 'react';
import Link from 'next/link';

import { Button, Flex } from '@chakra-ui/react';

import { ContentContainer } from '~/components/containers';
import { Logo } from '~/components/logo';

export const Header: FC = () => {
  return (
    <ContentContainer>
      <Flex justifyContent='space-between' as='header'>
        <Logo />

        <Link href='/log-in'>
          <a>
            <Button variant='outline' size='sm'>
              Log in
            </Button>
          </a>
        </Link>
      </Flex>
    </ContentContainer>
  );
};
